* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    scroll-behavior: smooth;
}

body {
    font-family: 'Inter', 'sans-serif';
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 100%;
    overflow-x: hidden;
}

@media (max-width: 445px) {
    .app {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        width: 100%;
        overflow-x: hidden;
    }
}

.commonIcons {
    font-size: 70px;
    margin-bottom: 15px;
}

.iconsTechStack {
    font-size: 25px;
    margin: 5px;
    /* flex-wrap: wrap; */
}

.commonIconsFooter {
    font-size: 25px;
    margin-right: 10px;
    /* margin-bottom: 15px; */
}

a:link {
    text-decoration: none;
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px dotte slategrey;
}

@font-face {
    font-family: 'Agustina Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Agustina Regular'), url('./Fonts/Agustina.woff') format('woff');
    font-display: swap;
}
